
h1, h2, h3, h4, h5 {
  font-family: 'IBM Plex Sans', sans-serif;
}

p, a, div, span {
  font-family: 'IBM Plex Sans', sans-serif;
}

.svg-inline--fa {
  margin-right: 5px;
  margin-left: 5px;
}

.tab-content {
  padding-top: 30px;
}