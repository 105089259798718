@keyframes highlight {
  0% {
    background: #ffff99;
  }
  100% {
    background: none;
  }
}

.highlight {
  animation: highlight 3s;
}
