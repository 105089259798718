.navbar-container .ui.menu {
  background-color: #000000;
}

.navbar-container {
  background-color: #000000;
}

.navbar-container .ui.menu .item {
  color: white;
}

.navbar-container .ui.menu .active.item:hover {
  color: #ecf0f1;
}

.navbar-container .ui.menu .active.item {
  color: white;
}
